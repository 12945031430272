<template>
    <div class="second-container-list">
        <div class="container-search">
            <input type="search" class="form-control" placeholder="Buscar cliente" />
            <div>
                <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal"
                    title="Agregar Crédito">
                    <box-icon name='add-to-queue'></box-icon>
                </button>
            </div>
        </div>
        <table class="table align-middle table-responsive table-hover caption-top">
            <thead>
                <tr align="center">
                    <th scope="col">Nombre de la Empresa</th>
                    <th scope="col">Fecha de Registro</th>
                    <th scope="col">Contacto</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col">Ver Contrataciones</th>
                    <th scope="col">Estatus</th>

                </tr>
            </thead>
            <tbody v-for="client in clients" v-bind:key="client">
                <tr align="center">
                    <td style="width:18%">{{ client.informationClient.nameCompany }}</td>
                    <td>{{ moment.utc(client.createdAt).format("DD/MM/YYYY") }} </td>
                    <td style="text-transform: capitalize">{{ client.informationClient.responsableCompany }}</td>
                    <td>{{ client.informationClient.phoneCompany }}</td>
                    <td>
                        <div class="select-list-evaluados">
                            <router-link class="btn-asignar-evaluacion"
                                :to="`/administration/clients/list/vacantes/${client.informationClient._id}`"
                                title="Ver Vacantes">
                                <box-icon name='graduation' type='solid' color="#03337A"></box-icon>
                            </router-link>
                        </div>
                    </td>
                    <td>
                        <span v-if="client.status === 'Activo'" class="badge text-bg-success">{{ client.status }}</span>
                        <span v-else class="badge text-bg-dark">{{ client.status }}</span>
                    </td>

                </tr>
            </tbody>
        </table>
        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Agregar Cliente</h1>
                        <button type="button" id="closeClient" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 3%;">
                            <span style="font-size: 18px;">
                                Selecciona una opción
                            </span>
                        </div>
                        <div class="form-floating">
                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                                required v-model="newClient">
                                <option value="" selected disabled>Seleccione una opción</option>
                                <option v-for="(item, i) in listCompany" :key="i + 1" :value="item">
                                    {{ item.nameCompany }}
                                </option>
                            </select>
                            <label for="floatingSelect">Empresa <sub class="indice">*</sub> </label>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" v-on:click="addClient()"
                            :disabled="newClient === ''">Agregar Cliente</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as ClientService from "../../services/client"
import moment from 'moment-timezone';
moment.tz.setDefault("America/Mexico_City");

export default {
    data: () => ({
        listCompany: [],
        newClient: "",
        clients: [],
        moment: moment,

    }),
    mounted() {
        const user = JSON.parse(localStorage.getItem("user") || null);
        const { company: { _id: _id_company } } = user
        this.listClient(_id_company);
        this.getCompany();
    },
    methods: {
        async getCompany() {
            var resp = await ClientService.getCompanies()
            var { data } = resp.data;
            this.listCompany = data
        },
        async addClient() {
            const user = JSON.parse(localStorage.getItem("user") || null);
            const { company: { _id: _id_company } } = user
            const { _id } = this.newClient
            await ClientService.addClient({ company: _id_company, client: _id }).then((response) => {
                this.$swal.fire({
                    icon: 'success',
                    title: 'Cliente agregado',
                    text: response.data.message,
                    timer: 3000,
                })
                this.listClient(_id_company);
            }).catch((err) => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3000,
                })
            }).finally(() => {
                const close = document.getElementById('closeClient')
                this.newClient = ""
                close.click()
            })
        },
        async listClient(filter) {
            console.log(filter)
            let resp = await ClientService.companyClientList({ _id_company: filter })
            this.clients = resp.data.data
            console.log(this.clients);
        },
    },
};

</script>
<style land="scss">
@import "../../styles/registerClient.scss";
</style>